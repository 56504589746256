/* You can add global styles to this file, and also import other style files */
@import './app/shared/styles/form';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*:not(mat-icon):not(span.material-icons) {
  font-family: Poppins !important;
}

table {
  width: 100%;
}

table th {
  font-weight: bold;
  color: black;
}

.gray-table-row {
  background-color: #f2f2f2;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

th {
  font-weight: bold;
  font-size: 15px;
}

.class-view-container {
  margin-left: 30px;
}
.mat-form-field.mat-focused .mat-form-field-label {
  color: #4f4f4f;
}

.mat-base, 
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #ac3345;
  color: #fff;
}

@import './app/shared/directives/mat-table-responsive/mat-table-responsive';

.alert {
  width: calc(100% - 20px);
  padding: 10px 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-error {
  color: #a94442;
  background-color: #f2dede;
  border-color: #e3e3e3;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #e3e3e3;
}

.alert-warn {
  color: #985f0d;
  background-color: #fcf8e3;
  border-color: #e3e3e3;
}

.form-field-border {
  padding: 10px;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background: #ac3345;
  color: #ac3345;
}

.trainee-table {
  background-color: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  border: 1px solid #e3e3e3;
}

.geco-red-button {
  background-color: #ac3345;
  color: white;
  margin: 5px 0;
}


.mat-table {
  box-shadow: none !important;
}

.mat-table,
.mat-paginator {
  background-color: transparent !important;
}

lib-full-table {
  .mat-toolbar {
    background-color: transparent !important;
  }
}

mat-spinner {
  margin: 40px auto !important;

  svg {
    circle {
      stroke: #ac3345 !important;
    }
  }
}

button.mat-raised-button.mat-primary, button.mat-flat-button.mat-primary {
  background-color: #ac3345;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background-color: #ac3345;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ac3345;
}

.mat-radio-button.mat-accent.mat-radio-checked {
  .mat-radio-outer-circle {
    border-color: #ac3345;
  }
  .mat-radio-inner-circle {
    background-color: #ac3345;
  }
}

mat-dialog-container {
  position: relative;
}

table.mat-table {
  border: 1px solid #e3e3e3;
  border-bottom: none;
  border-radius: 5px;
}

.mat-chip-list-wrapper {
  margin: 0 !important;
}

lib-full-table {
  .component-content {
    margin: 20px 0 0 0 !important;
  }
}